
  $green: #0B7189;
  $blue: #1B4079;
  $orange: #F46036;
  $red: #D7263D;
  $purple: #2E294E;

  $goal-colors: (
    'social': $green,
    'political': $purple,
    'intellectual': $orange,
    'individual': $purple,
    'cultural': $blue,
    'natural': $green,
    'built': $orange,
    'financial': $green
  );  


$spacing: 7px;
$width: 350px;
$height: 400px;

.kt-dashboard-app.wealth-works {
  .mado-dashboard {
    h3 {
      color: $blue;
      margin: 0.5em 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__metric-group {
      background-image: linear-gradient(158deg, rgba(255, 255, 255, 0.8), rgba(221, 220, 220, 0.8));
      background-color: transparent;
      box-shadow: 1px 1px 6px rgba(0,0,0,0.3);
      // background-color: $blue;
      color: $blue;
      margin: 14px 7px 0 7px;
      padding-bottom: 1.5em;
      backdrop-filter:blur(3px);
      &-silent {
        background: transparent;
        backdrop-filter: none;
        box-shadow: none;
      }
      small {
        display: inline-block;
        vertical-align: baseline;
        font-size: 0.7em;
        font-weight: light;
        margin-left: 0.5em;
      }

      .mado-metrics {
        .mado-metric-placeholder {
          width: $width + $spacing*2;
          height: $height + $spacing*4;
          margin-top: $spacing*2;
          margin-right: $spacing;
          margin-left: $spacing;
        }
        .mado-metric {
          padding: ($spacing*2) $spacing;
          width: $width;
          height: $height;
          background-color: $blue;
          color: $white;
          &__header {
            display: flex;
            &__icon {
              @include header-icon;
              &:before {
                font-size: 2rem;
              }
            }
          }
          &__button {
            margin-bottom: 4px;
          }
          &__chart {
            background-color: rgba(255, 255, 255, 0.8);
          }
          &:hover {
            background-color: $blue;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
          }
          &-expanded {
            width: 80%;
            height: auto;
            .mado-metric__header__expand {
              a {
                background-color: transparent;
                color: $white;
              }
            }
            .mado-metric__button {
              position: unset;
            }
          }
        }
      }


      @each $index, $color in $goal-colors {
        &.#{$index} {
          .mado-metric {
            background-color: $color;
            &:hover {
              background-color: darken($color, 5%)
            }
            &__button {
              color: $color;
              background: linear-gradient(#ffffff, lighten($color, 60%))
            }
            &__primary {
              &__value {
                color: $color;
              }
            }
          }
        }
      }

    }
  }
}