.kt-dashboard-app.mado {
  .mado-dashboard {

    &__metric-group {

      .mado-metric__header {
        &__expand {
          a {
            padding: 6px;
            box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
            cursor: pointer;
            color: $white;
            background-color: rgba(100,100,100,0.2);
          }
        }
      }

      // icons
      .mado-metric__header__icon {
        @include header-icon;
        @include meter-icon;
      }
      &.human-capital {
        .mado-metric__header__icon {
          @include person-icon;
        }
      }
    }
  }
}