@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9dkfxk');
  src: local('icomoon'),  url('../fonts/icomoon.eot?9dkfxk#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9dkfxk') format('truetype'),
    url('../fonts/icomoon.woff?9dkfxk') format('woff'),
    url('../fonts/icomoon.svg?9dkfxk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"], .iconify {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-wealth-works_cultural:before {
  content: "\e91d";
  color: #fff;
}
.icon-wealth-works_political:before {
  content: "\e918";
  color: #fff;
}
.icon-wealth-works_natural:before {
  content: "\e919";
  color: #fff;
}
.icon-wealth-works_intellectual:before {
  content: "\e91a";
  color: #fff;
}
.icon-wealth-works_individual:before {
  content: "\e91b";
  color: #fff;
}
.icon-wealth-works_financial:before {
  content: "\e91c";
  color: #fff;
}
.icon-wealth-works_built:before {
  content: "\e929";
  color: #fff;
}
.icon-wealth-works_social:before {
  content: "\e917";
  color: #fff;
}
.icon-sdg_17:before {
  content: "\e900";
  color: #fff;
}
.icon-sdg_16:before {
  content: "\e901";
  color: #fff;
}
.icon-sdg_15:before {
  content: "\e902";
  color: #fff;
}
.icon-sdg_14:before {
  content: "\e903";
  color: #fff;
}
.icon-sdg_13:before {
  content: "\e904";
  color: #fff;
}
.icon-sdg_12:before {
  content: "\e905";
  color: #fff;
}
.icon-sdg_11:before {
  content: "\e906";
  color: #fff;
}
.icon-sdg_10:before {
  content: "\e907";
  color: #fff;
}
.icon-sdg_9:before {
  content: "\e908";
  color: #fff;
}
.icon-sdg_8:before {
  content: "\e909";
  color: #fff;
}
.icon-sdg_7:before {
  content: "\e90a";
  color: #fff;
}
.icon-sdg_6:before {
  content: "\e90b";
  color: #fff;
}
.icon-sdg_5:before {
  content: "\e90c";
  color: #fff;
}
.icon-sdg_4:before {
  content: "\e90d";
  color: #fff;
}
.icon-sdg_3:before {
  content: "\e90e";
  color: #fff;
}
.icon-sdg_2:before {
  content: "\e90f";
  color: #fff;
}
.icon-sdg_1:before {
  content: "\e910";
  color: #fff;
}
.icon-fullscreen-exit:before {
  content: "\e915";
}
.icon-fullscreen:before {
  content: "\e911";
}
.icon-meter:before {
  content: "\e916";
}
.icon-road:before {
  content: "\e912";
}
.icon-stats-dots:before {
  content: "\e913";
}
.icon-users:before {
  content: "\e914";
}
.icon-group:before {
  content: "\e914";
}
.icon-team:before {
  content: "\e914";
}
.icon-members:before {
  content: "\e914";
}
.icon-community:before {
  content: "\e914";
}
.icon-collaborate:before {
  content: "\e914";
}
